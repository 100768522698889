import React, { useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

const DatePicker = ({ field, form }) => {
    const datepickerRef = useRef(null);

    useEffect(() => {
        flatpickr(datepickerRef.current, {
            dateFormat: "Y-m-d", // Formato de data no padrão MySQL
            altInput: true, // Usar um campo de entrada alternativo
            altFormat: "j F Y", // Formato de exibição alternativo
            onChange: (selectedDates, dateStr) => {
                form.setFieldValue(field.name, dateStr); // Atualiza o valor no Formik
            },
        });
    }, [field.name, form]);

    return (
        <div>
            <input
                ref={datepickerRef}
                placeholder="Selecione uma data"
                {...field}
            />
        </div>
    );
};

export default DatePicker;
