import './App.css';
import SendRequest from './pages/admin/createService/sendRequest';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Welcome from './welcome';
import WatchService from './pages/watchService/watchService';
import HomeAdmin from './pages/admin/Home/HomeAdmin';
import HistoryOrders from './pages/admin/createService/HistoryOrder/HistoryOrder';

function App() {
  return (

      <Router>
        <Routes>
          <Route path='/'element ={<Welcome/>}/>
          <Route path='/CreateService' element = {<SendRequest/>}/>
          <Route path='/HomeAdmin' element = {<HomeAdmin/>}/>
          <Route path='/ViewService' element = {<WatchService/>}/>
          <Route path='/HistoryOrders' element = {<HistoryOrders/>}/>

        </Routes>
      </Router>

  );
}

export default App;
