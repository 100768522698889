import React from "react";
import './header.css'

const Header = () =>{
return(
    <main className="header">
        <div className="titleApp">
            <div className="confTitle">
                <h1 className="teste">OCTView</h1>
            </div>
        </div>
    </main>
)
}

export default Header;