import { useEffect, useState } from "react";
import axios from "axios";
import "./HistoryOrder.css";

const HistoryOrders = () => {
  const [neworder, SetOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [employeeName, setEmployeeName] = useState(""); // Para filtrar pelo nome do funcionário
  const [startDate, setStartDate] = useState(""); // Para a data de início
  const [endDate, setEndDate] = useState(""); // Para a data de fim
  const [statusFilter, setStatusFilter] = useState("");// Para Status

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get("https://app.chamaoseuze.com.br/findAllServices");
        SetOrders(response.data);
        setFilteredOrders(response.data); // Inicializa o estado de pedidos filtrados com todos os pedidos
      } catch (error) {
        console.error("Houve um erro ", error);
      }
    };

    const interval = setInterval(fetchOrder, 100);

    fetchOrder();
  }, []);

  // Função para filtrar as ordens
  useEffect(() => {
    const filtered = neworder.filter(order => {
      const orderDate = new Date(order.data); // Converte a data da ordem para objeto Date
      const start = startDate ? new Date(startDate) : null; // Converte a data de início, se fornecida
      const end = endDate ? new Date(endDate) : null; // Converte a data de fim, se fornecida
  
      // Verifica se o nome do funcionário contém o texto do filtro (não diferencia maiúsculas/minúsculas)
      const matchesEmployee = order.provider.toLowerCase().includes(employeeName.toLowerCase());
  
      // Verifica se a data está dentro do intervalo, se as datas foram fornecidas
      const matchesDate = (!start || orderDate >= start) && (!end || orderDate <= end);
  
      // Verifica se o status corresponde ao filtro de status
      const matchesStatus = !statusFilter || order.status === statusFilter;
  
      // Retorna true se todas as condições forem satisfeitas
      return matchesEmployee && matchesDate && matchesStatus;
    });
  
    setFilteredOrders(filtered);
  }, [employeeName, startDate, endDate, statusFilter, neworder]);


  return (
    <main className="mainHistoryOrder">
      <div className="titleHistory">
        <h1>Histórico</h1>
      </div>

      {/* Inputs de Filtro */}
      <div className="filters">
        <input
          className="inputHistory"
          type="text"
          placeholder="Filtrar por funcionário"
          value={employeeName}
          onChange={e => setEmployeeName(e.target.value)}
        />

        <input
          type="date"
          placeholder="Data de início"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />

        <input
          type="date"
          placeholder="Data de fim"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />

        <select value={statusFilter} onChange={e => setStatusFilter(e.target.value)}>
            <option value="">Todos os status</option> {/* Opção sem filtro */}
            <option value="Pendente">Pendente</option> 
            <option value="Em Andamento">Em Andamento</option>
        </select>
      </div>

      {/* Lista de Serviços Filtrados */}
      <div className="services-listHistory">
        {filteredOrders.length > 0 ? (
          filteredOrders.map((order, index) => (
            <div key={index} className="service-itemHistory">
              <h2>Ordem: {order.numOrder}</h2>
              <h2>Cliente: {order.client}</h2>
              <h2>Status: {order.status}</h2>
              <h2>Funcionário: {order.provider}</h2>
              <p>Defeito: {order.service}</p>
              <p>Data: {new Date(order.data).toISOString('pt-BR').split('T')[0]}</p>
            </div>
          ))
        ) : (
          <p>Nenhum chamado disponível</p>
        )}
      </div>
    </main>
  );
};

export default HistoryOrders;
