import React from "react";
import { Link } from "react-router-dom";
import "./sideBar.css"

const SideBar = () => {
    return(
        <section className="mainSideBar">
                <ul className="ulSideBar">
                    <li><Link to={"/CreateService"} className="btn-link">Criar Ordem</Link></li>
                    <li><Link to={"/HistoryOrders"} className="btn-link">Ver Histórico</Link></li>
                </ul>

        </section>
    )
}

export default SideBar;