import React from "react";
import SideBar from "../../../components/sideBar/sideBar";
import Header from "../../../components/header/header";
import "./HomeAdmin.css"
const HomeAdmin = ()=>{


    return(    
        <main className="homeAdmin">
            <Header/>
            <SideBar/>

            <div className="contentHomeAdmin">
                <div>
                    <h1>
                        Bem-Vindos
                    </h1>
                </div>
            </div>

        </main>
    )
}

export default HomeAdmin;
