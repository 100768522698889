import { Link } from "react-router-dom";
import "./welcome.css"


const Welcome = ()=>{
    return(
        <main className="welcome">
            <Link to={"/CreateService"} className="btn-welcome1">Criar Ordem</Link>
            <Link to={"/ViewService"} className="btn-welcome2">Ver Ordens</Link>
            <Link to={"/HomeAdmin"} className="btn-welcome2">Admin</Link>
        </main>
    )
}

export default Welcome;